import React, { Component } from 'react'
import Header from '../common/header'
import Footer from '../common/footer'
import Partners from '../common/partners';
import { useTranslation } from 'react-i18next'
import Links from '../common/links'
import { Container } from '@mui/system'
import News1 from '../Images/news_1.jpg'
import News2 from '../Images/news_2.jpg'
import News3 from '../Images/news_3.jpg'
import { Button } from '@mui/material'

const News = () => {
    const { t } = useTranslation();
        return (
                <div style={{ minHeight: '100VH' }}>
                    <Header>
                    </Header>
                    <Links></Links>
                    <div style={{background: 'rgba(0, 0, 0, 0.2)'}}>
                        <p style={{ color: '#000000' }} className="title-text">{t('news_title_1')}</p>
                        <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%', alignItems:'center' }}>
                            <img src={News1} className="folding-image" style={{ objectFit: 'contain', width:'40%', paddingLeft:'2vW', paddingBottom:'2vH'}} alt='' />
                            <Container style={{right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                                <p style={{textAlign:'justify'}}>{t("news_text_1")}</p>
                            </Container>
                        </div>
                        <Button style={{margin:'5px'}} variant="contained" color="success"  href={t("news_link_1")} target="_blank">{t('read_more')}</Button>
                    </div>
                    <div>
                        <p style={{ color: '#000000' }} className="title-text">{t('news_title_2')}</p>
                        <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%',alignItems:'center' }}>
                            <Container style={{right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                                <p style={{textAlign:'justify'}}>{t("news_text_2")}</p>
                            </Container>
                            <img src={News2} className="folding-image" style={{ objectFit: 'contain',  width:'40%', maxHeight:'450px', paddingRight:'2vW', paddingBottom:'2vH'}} alt='' />
                        </div>
                        <Button style={{margin:'5px'}} variant="contained" color="success"  href={t("news_link_2")} target="_blank">{t('read_more')}</Button>     
                    </div>
                    <div>
                        <p style={{ color: '#000000' }} className="title-text">{t('news_title_3')}</p>
                        <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%',alignItems:'center' }}>
                        <img src={News3}  className="folding-image" style={{ objectFit: 'contain',  width:'40%', paddingLeft:'2vW', paddingBottom:'2vH'}} alt='' />
                            <Container style={{right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                                <p style={{textAlign:'justify'}}>{t("news_text_3")}</p>
                            </Container>
                        </div>
                        <Button style={{margin:'5px'}} variant="contained" color="success"  href={t("news_link_3")} target="_blank">{t('read_more')}</Button>     
                    </div>
                    <Partners></Partners>
                    <Footer></Footer>
                </div>
            )
    }

export default News