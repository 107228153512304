import React,{ useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Logo from '../Images/logo.png'
import { Link } from 'react-router-dom';
import LanguageSelector from './languageSelector';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();
    const [isSticky, setIsSticky] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const headerClass = isSticky ? 'sticky-header sticky' : 'sticky-header';

    return (
        <div className={headerClass}>
            <div style={{ display: 'flex', justifyContent: 'right', paddingRight: '4vW',background: 'white' }}>
                <LanguageSelector />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',backgroundColor: 'white', flexWrap: 'wrap', alignItems: 'center', paddingLeft: '4vW'}}>
                <Link to="/"><img src={Logo} style={{ height: '7VH' }} alt='' /></Link>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right'}}>
                    <Button style={{ height: '45px', marginRight:'4vW'  }} onClick={() => navigate('/about')}>
                        <p style={{ color: '#132E36', fontSize:'1.9VH' }} className="title"><b>{t("about_title")}</b></p>
                    </Button>
                    <Button style={{ height: '45px', marginRight:'4vW' }} onClick={() => navigate('/news')}>
                        <p style={{ color: '#132E36', fontSize:'1.9VH' }} className="title" ><b>{t("news_title")}</b></p>
                    </Button>
                    <Button style={{ height: '45px', marginRight:'4vW' }} onClick={() => navigate('/courses')}>
                        <p style={{ color: '#132E36', fontSize:'1.9VH' }} className="title" ><b>{t("courses_title")}</b></p>
                    </Button>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'right', paddingRight: '4vW',background: 'white'  }}>
            </div>
        </div>
    )
}

export default Header