import { Container } from '@mui/system'
import React, { Component } from 'react'
import Header from '../common/header'
import Footer from '../common/footer'
import { useTranslation } from 'react-i18next'
import Image1 from '../Images/image01.jpg'
import Image4 from '../Images/image04.jpg'
import Image5 from '../Images/e-learning.jpg'
import Partners from '../common/partners';
import Members from '../common/members';
import Links from '../common/links'

const About  = () =>{
        const { t } = useTranslation();
        return (
            <div style={{ minHeight: '100VH' }}>
                <Header>
                </Header>
                <Links></Links>
                <div style={{background: 'rgba(0, 0, 0, 0.2)'}}>
                    <p style={{ color: '#000000' }} className="title-text">{t('about_title')}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%', alignItems:'center' }}>
                        <img src={Image1} className="folding-image" style={{ objectFit: 'contain',height:'auto', width:'40%', paddingLeft:'2vW', paddingBottom:'2vH'}} alt='' />
                        <Container style={{right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                            <p style={{textAlign:'justify'}}>{t("about_text")}</p>
                        </Container>
                    </div>
                </div>
                <div>
                    <p style={{ color: '#000000' }} className="title-text">{t('goals')}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%',alignItems:'center' }}>
                        <Container style={{right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                            <p style={{textAlign:'justify'}}>{t("goals_text")}</p>
                        </Container>
                        <img src={Image4} className="folding-image" style={{ objectFit: 'contain', width:'auto',  width:'40%', maxHeight:'450px', paddingRight:'2vW', paddingBottom:'2vH'}} alt='' />
                    </div>     
                </div>
                <div>
                    <p style={{ color: '#000000' }} className="title-text">{t('projects')}</p>
                    <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%',alignItems:'center' }}>
                    <img src={Image5}  className="folding-image" style={{ objectFit: 'contain', width:'auto',  width:'40%', paddingLeft:'2vW', paddingBottom:'2vH'}} alt='' />
                        <Container style={{right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                            <p style={{textAlign:'justify'}}>{t("project_text")}</p>
                        </Container>
                    </div>     
                </div>
                {/* this part is fixed for mobile */}
                <div style={{background: 'rgba(0, 0, 0, 0.2)'}}>
                    <p style={{ color: '#000000' }} className="title-text">{t('our_team')}</p>
                    <Container style={{right:'0',paddingBottom:'5vW'}}>
                        <p style={{ color: '#000000', fontSize: '1rem',textAlign:'justify' }} >{t('team_text')}</p>
                    </Container>
                    <div spacing={2} style={{ display: 'flex', flexDirection: 'row',  alignItems: 'center',  flexWrap: 'wrap', justifyContent:'center' }}>
                        <Members></Members>
                    </div>
                </div>
                <Partners></Partners>
                <Footer></Footer>
            </div>
        )
}

export default About