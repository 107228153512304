import { Container } from '@mui/system'
import React, { Component } from 'react'
import { useTranslation } from 'react-i18next'
import Member1 from '../Images/Milojevic.png'
import Member2 from '../Images/Gajevic.png'
import { Button } from '@mui/material'

const Members = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between' , paddingRight:'10px', paddingLeft:'10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row',background:'#e5e5e5', borderRadius:'25px', marginBottom:'5VH' , marginRight: '30px' }}>
                    <img src={Member1} style={{ height: '200px', width: 'auto', borderRadius: '25px', borderStyle: 'solid', borderColor: '#cdd3d1' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '220px',  maxWidth:'480px', wordWrap: 'break-word',padding:'5px'}}>
                        <p>{t('member_name01')}</p>
                        <p style={{height:'110px'}}>{t('text_portfolio01')}</p>
                        <Button variant="contained" color="success" href={t('link_to_01')}>{t('bio')}</Button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row',background:'#e5e5e5', borderRadius:'25px', marginBottom:'5VH' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '220px', maxWidth:'480px', wordWrap: 'break-word',padding:'5px'}}>
                        <p>{t('member_name02')}</p>
                        <p style={{height:'110px'}}>{t('text_portfolio02')}</p>
                        <Button variant="contained" color="success" href={t('link_to_02')}>{t('bio')}</Button>
                    </div>
                    <img src={Member2} style={{ height: '200px', width: 'auto', borderRadius: '25px', borderStyle: 'solid', borderColor: '#cdd3d1' }} />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', paddingRight:'10px', paddingLeft:'10px'  }}>
                <div style={{ display: 'flex', flexDirection: 'row',background:'#e5e5e5', borderRadius:'25px', marginBottom:'5VH'  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '220px', maxWidth:'480px', wordWrap: 'break-word',padding:'5px' }}>
                        <p>{t('member_name03')}</p>
                        <p style={{height:'110px'}}>{t('text_portfolio03')}</p>
                        <Button variant="contained" color="success" href={t('link_to_03')}>{t('bio')}</Button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row',background:'#e5e5e5', borderRadius:'25px', marginBottom:'5VH'  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '220px', maxWidth:'480px', wordWrap: 'break-word',padding:'5px' }}>
                        <p>{t('member_name04')}</p>
                        <p style={{height:'110px'}}>{t('text_portfolio04')}</p>
                        <Button variant="contained" color="success" href={t('link_to_04')}>{t('bio')}</Button>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', paddingRight:'10px', paddingLeft:'10px'  }}>
                <div style={{ display: 'flex', flexDirection: 'row',background:'#e5e5e5', borderRadius:'25px', marginBottom:'5VH'  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '220px', maxWidth:'480px', wordWrap: 'break-word',padding:'5px'}}>
                        <p>{t('member_name05')}</p>
                        <p style={{height:'110px'}}>{t('text_portfolio05')}</p>
                        <Button variant="contained" color="success" href={t('link_to_05')}>{t('bio')}</Button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row',background:'#e5e5e5', borderRadius:'25px', marginBottom:'5VH'  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '220px', maxWidth:'480px', wordWrap: 'break-word',padding:'5px' }}>
                        <p>{t('member_name06')}</p>
                        <p style={{height:'110px'}}>{t('text_portfolio06')}</p>
                        <Button variant="contained" color="success" href={t('link_to_06')}>{t('bio')}</Button>
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', paddingRight:'10px', paddingLeft:'10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row',background:'#e5e5e5', borderRadius:'25px' , marginBottom:'5VH' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '220px', maxWidth:'480px', wordWrap: 'break-word',padding:'5px' }}>
                        <p>{t('member_name07')}</p>
                        <p style={{height:'110px'}}>{t('text_portfolio07')}</p>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row',background:'#e5e5e5', borderRadius:'25px', marginBottom:'5VH'  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '220px', maxWidth:'480px', wordWrap: 'break-word',padding:'5px' }}>
                        <p>{t('member_name08')}</p>
                        <p style={{height:'110px'}}>{t('text_portfolio08')}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Members