import React, { Component, useState, useEffect } from 'react'
import Header from '../common/header'
import Footer from '../common/footer'
import { useTranslation } from 'react-i18next'
import Partners from '../common/partners';
import Image1 from '../Images/MOTS.jpg'
import Image2 from '../Images/UTD.jpg'
import Image3 from '../Images/request course_1.jpg'
import Image4 from '../Images/Register as teacher_1.jpg'
import { Button } from '@mui/material'
import i18n from '../i18n';
import Curriculum1 from '../documents/KurikulumObukaOTS.pdf'
import Curriculum2 from '../documents/CourseCurriculumOTS - EN.pdf'
import Curriculum3 from '../documents/Kurikulum_Obuka_ UTD.pdf'
import Curriculum4 from '../documents/Kurikulum_Obuka_UTD-EN.pdf'
import Links from '../common/links'

const Courses = () => {
    const { t } = useTranslation();
    const language =   i18n.language;
    const [isColumnLayout, setIsColumnLayout] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsColumnLayout(window.innerWidth < 768); // Change breakpoint as needed
        };
    
        // Initial check
        handleResize();
    
        // Add event listener to handle window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

        return (
            <div style={{ minHeight: '100VH' }}>
                <Header></Header>
                <Links></Links>
                <div style={{background: 'rgba(0, 0, 0, 0.2)'}}> 
                        <div  className={`container ${isColumnLayout ? 'column-layout' : 'row-layout'}`} style={{ display: 'flex', minWidth: '100%', alignItems:'center' }}>
                            {/* <img  className="folding-image" src={Image1} style={{ objectFit: 'contain',height:'auto', width:'40%', paddingLeft:'2vW', paddingBottom:'2vH',paddingTop:'2vH'}} alt='' /> */}
                            <iframe  style={{ paddingLeft:'2vW', paddingBottom:'2vH',paddingTop:'2vH', width:"90%", height:"50VH"}}  src="https://www.youtube.com/embed/mXJUBJBUN4Q" title="KURS OPTIMIZACIJA TEHNIČKIH SISTEMA - Prof. dr Nenad Marjanović" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                            <div className="backdrop-image" style={{right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                                <p className="course-title-text" style={{ color: '#000000'}} >{t('course_title01')}</p>
                                <p style={{textAlign:'justify'}}>{t("course_subtitle01")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text01_part0")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text01_part1")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text01_part2")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text01_part3")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text01_part4")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text01_part5")}</p>
                                <p style={{textAlign:'justify'}}>{t("course1_timetable")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_footer_text")}</p>
                                { language ==='rs'? <Button style={{margin:'5px'}} variant="contained" color="success"  href={Curriculum1} target="_blank">{t('curriculum')}</Button> : <Button style={{margin:'5px'}} variant="contained" color="success"  href={Curriculum2}>{t('curriculum')}</Button> } <Button style={{margin:'5px'}} variant="contained" color="primary"  href='https://docs.google.com/forms/d/e/1FAIpQLSfEIDgWVE72DVRmJo15QKYgUl_8zWOt2ElvAsteljVWARUFPQ/viewform' target="_blank">{t('enroll_course')}</Button> <Button variant="contained" color="success"  href='https://moodle.kgedus.com/'>{t('take_course')}</Button>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div  className={`container ${isColumnLayout ? 'column-layout' : 'row-layout'}`} style={{ display: 'flex', minWidth: '100%', alignItems:'center' }}>
                        <div className="backdrop-image" style={{ right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                                <p className="course-title-text" style={{ color: '#000000',}} >{t('course_title02')}</p>
                                <p style={{textAlign:'justify'}}>{t("course_subtitle02")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text02_part0")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text02_part1")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text02_part2")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text02_part3")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text02_part4")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text02_part5")}</p>
                                <p style={{textAlign:'justify'}}>{t("course2_timetable")}</p>
                                <p style={{textAlign:'justify'}}>{t("course_footer_text")}</p>
                                { language ==='rs'? <Button style={{margin:'5px'}} variant="contained" color="success"  href={Curriculum3} target="_blank">{t('curriculum')}</Button> : <Button  style={{margin:'5px'}} variant="contained" color="success"  href={Curriculum4}>{t('curriculum')}</Button> } <Button style={{margin:'5px'}} variant="contained" color="primary"  href='https://docs.google.com/forms/d/e/1FAIpQLSfEIDgWVE72DVRmJo15QKYgUl_8zWOt2ElvAsteljVWARUFPQ/viewform' target="_blank">{t('enroll_course')}</Button> <Button variant="contained" color="success" href='https://moodle.kgedus.com/'>{t('take_course')}</Button>
                            </div>
                            {/* <img  className="folding-image" src={Image2} style={{ objectFit: 'contain',height:'auto', width:'40%', paddingLeft:'2vW', paddingBottom:'2vH',  paddingTop:'2vH'}} alt='' />   */}
                            <iframe style={{ paddingRight:'2vW', paddingBottom:'2vH',paddingTop:'2vH', width:"90%", height:"50VH"}}  src="https://www.youtube.com/embed/3J68heNXWW0" title="KURS UPRAVLJANJE TEHNIČKOM DOKUMENTACIJOM - Prof. dr Blaža Stojanović" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div style={{background: 'rgba(0, 0, 0, 0.2)'}}> 
                        <div style={{ display: 'flex', minWidth: '100%', alignItems:'center' }}>
                            <img className="folding-image" src={Image3} style={{ objectFit: 'contain',height:'auto', width:'20%', paddingLeft:'2vW', paddingBottom:'2vH',paddingTop:'2vH'}} alt='' />
                            <div className="backdrop-image" style={{right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                                <p className="course-title-text" style={{ color: '#000000'}} >{t('course_title03')}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text03_part0")}</p>
                                <Button style={{margin:'5px'}} variant="contained" color="primary"  href='https://docs.google.com/forms/d/e/1FAIpQLSffkywNYMW4n_QJGkDeizOkJhk1ewK7JCHK6-n7FtMVtkw-0w/viewform' target="_blank">{t('request_course')}</Button>
                            </div>
                        </div>
                    </div>
                    <div >
                        <div style={{ display: 'flex', flexDirection: 'row', minWidth: '100%', alignItems:'center' }}>
                        <div className="backdrop-image" style={{ right:'0', paddingRight:'5vW', paddingLeft:'5vW'}}>
                                <p className="course-title-text" style={{ color: '#000000',}} >{t('course_title04')}</p>
                                <p style={{textAlign:'justify'}}>{t("course_text04_part0")}</p>
                                <Button style={{margin:'5px'}} variant="contained" color="primary"  href='https://docs.google.com/forms/d/e/1FAIpQLSc1g36rtNK3wT9lym3lueMrwHlyJgU0mEov90vAWfsHgY1gPQ/viewform' target="_blank">{t('signup')}</Button>
                            </div>
                            <img className="folding-image" src={Image4} style={{ objectFit: 'contain',height:'auto', width:'20%', paddingLeft:'2vW', paddingBottom:'2vH',  paddingTop:'2vH'}} alt='' />
                        </div>
                    </div>
                <Partners></Partners>
                <Footer></Footer>  
            </div>
        )
}

export default Courses