import React from 'react'
import { useTranslation } from 'react-i18next';
import Partner01 from '../Images/partner01.png'
import Partner01Rs from '../Images/partner01rs.png'
import Partner02 from '../Images/partner02.png'
import i18n from '../i18n';
const Partners = () => {
    const { t } = useTranslation();
    const language =   i18n.language;
    return (
        <div style={{ backgroundColor: '', height: '20%', width: '100%',marginBottom:'20px' }}>
            <p className="titles" style={{ fontSize: '1.5Rem', marginBottom: '0', color: '#9b9b9b' }}>{t('partners')}</p>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-around', flexDirection: 'row' }}>
                <div  className='partner-div' style={{display: 'flex', alignItems: 'center', justifyContent:'center'}}>
                { language ==='rs'? <img src={Partner01Rs} style={{ width: '88%' }} /> : <img src={Partner01} style={{ width: '88%' }} />}
                </div>
                <div  className='partner-div' style={{display: 'flex', alignItems: 'center',  justifyContent:'center'}}>
                    <img src={Partner02} style={{ width: '88%' }} />
                </div>
            </div>
        </div>
    )
}

export default Partners