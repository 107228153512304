import React, {useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Logo from '../Images/footer_logo.png'
import Links from './links';
import RoomIcon from '@mui/icons-material/Room';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Document from './document'


const Footer = () => {
    const { t } = useTranslation();
    const [open, setOpen, title,text] = React.useState(false);
    const [doc, setDoc] = React.useState(0);
    const handleClickOpen = (e) => {
        setOpen(true);
        setDoc(e);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div >
            <div className="footer_grid" style={{ backgroundColor: '#cfcfcf', width: '100%' }}>
                <div className="footer_item"style={{ display: 'flex', alignItems: 'center' }} >
                    <Link to="/"><img src={Logo} style={{ height: '180px' }} alt='' /></Link>
                </div>
                <div className="footer_item" style={{ display: 'flex', flexDirection: 'column', alignItems: 'top' }}>
                    <p className="title-text" >{t('information')}</p>
                    <p style={{ fontSize: '1.5VH' }} >{t('information_text')}</p>

                </div>
                <div className="footer_item"alt="tekst" style={{ display: 'flex', flexDirection: 'column'}}>
                    <p className="title-text" >{t('documentation')}</p>
                    <p className="titles" style={{ fontSize: '1.5VH',cursor: 'pointer' }} onClick={() => handleClickOpen(1)}>{t('privacy_title')}</p>
                    <p className="titles" style={{ fontSize: '1.5VH',cursor: 'pointer' }} onClick={() => handleClickOpen(2)}>{t('terms_title')}</p>
                    <Dialog
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                backgroundColor: 'white',
                                boxShadow: 'none',
                            },
                        }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        maxWidth='80VW'

                    >
                        <DialogContent style={{ padding: '0', borderRadius: '25px' }}>
                            <Document title={doc} />
                        </DialogContent>
                    </Dialog>
                </div>
                <div className="footer_item" alt="kontakt" style={{ display: 'flex', flexDirection: 'column'}}>
                    <p className="title-text" >{t('contact')}</p>
                    <Links></Links>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}><AlternateEmailIcon style={{ paddingRight: '5px' }} /><p> office@kgedus.com </p></div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}><RoomIcon style={{ paddingRight: '5px' }} /><p> 34000 Kragujevac, Serbia</p></div>
                </div>
            </div>
        </div>
    )
}

export default Footer