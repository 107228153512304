import React from 'react'
import { useTranslation } from 'react-i18next';
import Partner01 from '../Images/partner01.png'
import Partner01Rs from '../Images/partner01rs.png'
import Partner02 from '../Images/partner02.png'
import i18n from '../i18n';
const Table = () => {
    const { t } = useTranslation();
    const language =   i18n.language;
    return (
      <table style={{ display: 'flex',padding: '5vW', padding:'5vW',flexDirection: 'column', justifyContent:'center'}}>
        <thead>
          <tr style={{ display: 'flex', flexDirection: 'row', width:'100%'}}>
            <th style={{ display: 'flex', width: '50%',justifyContent:'center', alignItems:'center'}}>{t('request_a_course')}</th>
            <th style={{ display: 'flex', width: '50%',justifyContent:'center', alignItems:'center'}}>{t('offer_as_course')}</th>
          </tr>
        </thead >
        {language ==='en'?<tbody style={{ display: 'flex', paddingTop: '3vW' }}>
          <tr style={{ display: 'flex', flexDirection: 'column', width: '50%'}}>
            <td>If you have a need for training in your field of business, you can send us a request so that we can create a customized course for you, whether you are a company or an individual</td>
            <td>Click on the <a href="https://docs.google.com/forms/d/e/1FAIpQLSffkywNYMW4n_QJGkDeizOkJhk1ewK7JCHK6-n7FtMVtkw-0w/viewform" target="_blank">link</a> and fill out the course creation request. Our team will respond to your request as soon as possible.</td>
          </tr>
          <tr style={{ display: 'flex', flexDirection: 'column', width: '50%'}}>
            <td>If you have expertise in a particular field and want to share your knowledge to best represent yourself or your company, apply as a lecturer by clicking on the <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSc1g36rtNK3wT9lym3lueMrwHlyJgU0mEov90vAWfsHgY1gPQ/viewform">link</a>.</td>
            <td>Our team will contact you to discuss the details.</td>
          </tr>
        </tbody>:<tbody style={{ display: 'flex', paddingTop: '3vW' }}>
          <tr style={{ display: 'flex', flexDirection: 'column', width: '50%'}}>
            <td>Ukoliko imate potrebu za obukom iz vaše oblasti poslovanja, možete nam poslati zahtev kako bi smo kreirali kurs po vašoj meri, bilo da ste firma ili pojedinac.</td>
            <td>Kliknite na <a href="https://docs.google.com/forms/d/e/1FAIpQLSffkywNYMW4n_QJGkDeizOkJhk1ewK7JCHK6-n7FtMVtkw-0w/viewform" target="_blank">link</a> I popunite zahtev za kreiranje kursa. Naš tim će u najkraćem roku odgovoriti na Vaš zahtev.</td>
          </tr>
          <tr style={{ display: 'flex', flexDirection: 'column', width: '50%'}}>
            <td>Ukoliko imate iskusto u određenoj oblasti i želite da podelite znanje i tako na najbolji način prezentujete sebe ili Vašu kompaniju, prijavite se kao predavač klikom na <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSc1g36rtNK3wT9lym3lueMrwHlyJgU0mEov90vAWfsHgY1gPQ/viewform">link</a>.</td>
            <td>Naš tim će vas kontaktirati i ugovorićemo detalje.</td>
          </tr>
        </tbody>}
      </table>
    );
  };

export default Table