import './App.css';
import Home from './home/home'
import Courses from './courses/courses';
import News from './news/news';
import About from './about/about';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/"  element={<Home></Home>} />
          <Route path="/about"  element={<About> </About>} />
          <Route path="/courses"  element={<Courses></Courses>} />
          <Route path="/news"  element={<News></News>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
