import React, {useState} from "react";
import i18n from '../i18n';
import { IconButton } from "@mui/material";
import EnFlag from "./../Images/united-states.png";
import RsFlag from "./../Images/serbia.png";
import { BorderStyle } from "@mui/icons-material";

const LanguageSelector = () => {
    
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (language) => {
        const lang = language
        i18n.changeLanguage(lang);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(lang);
    }

    return (
        <div>
            <IconButton  onClick={() => chooseLanguage('en')} className="dropdown-item"><img  src={EnFlag} style={{width: '3vH', marginRight:'1vW',borderRadius: '20px', borderStyle:'solid', borderColor:'#cdd3d1'}} alt=""/></IconButton>
            <IconButton  onClick={() => chooseLanguage('rs')} className="dropdown-item"><img  src={RsFlag} style={{width: '3vH', borderRadius: '20px',borderRadius: '20px', borderStyle:'solid', borderColor:'#cdd3d1'}} alt=""/></IconButton>
        </div>
    );
};

export default LanguageSelector;