import * as React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Link } from 'react-router-dom';



function Links() {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', flexWrap: 'wrap' }}>
            <div>
            <Link to="https://www.facebook.com/kgedu.s/"><FacebookIcon alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px', color:'#000000'}} /></Link>
            </div>
            <div>
            <Link to="https://instagram.com/kgedu.s"><InstagramIcon alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px', color:'#000000'}} /></Link>
            </div>
            <div>
            <Link to="https://www.linkedin.com/in/kg-edu-s-b63b17289"><LinkedInIcon alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px', color:'#000000'}} /></Link>
            </div>
            <div>
            <Link to="https://twitter.com/KGEDUs"><TwitterIcon alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px', color:'#000000'}} /></Link>
            </div>
            <div>
            <Link to="https://youtube.com/@KGEDU-s"><YouTubeIcon alt='' style={{ width: '50px', borderRadius: '100%', padding: '8px', color:'#000000'}} /></Link>
            </div>
        </div>
    )
}

export default Links