import React from "react";
import { useTranslation } from 'react-i18next';

const Document = (props) => {
    const { t } = useTranslation();

    return (
        <div style={{ height: '70vH', width:'60vW', justifyContent:'space-around', alignItems:'center', padding:'5vW'}}>
            <div style={{textAlign:'center', paddingBottom:'5vH'}}>
            { props.title === 1 ?<p  className="titles">{t('privacy_title')}</p>:<p  className="titles">{t('terms_title')}</p>}
            </div>
            {props.title === 1 ? <p style={{textAlign:'justify'}}>{t('privacy_policy')}</p>:<p style={{textAlign:'justify'}}>{t('terms_use')}</p>}
        </div>
    );
};

export default Document;