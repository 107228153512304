import React, { useState, useEffect } from 'react'
import { Container } from '@mui/system'
import Header from '../common/header';
import Footer from '../common/footer';
import Partners from '../common/partners';
import Friends01 from '../Images/metalac.jpg'
import Friends02 from '../Images/mrstil.jpg'
import Friends03 from '../Images/politek.jpg'
import Friends04 from '../Images/IC.jpg'
import Friends05 from '../Images/gosa.jpg'
import Background from '../Images/image02.jpg'
import ComingSoon from '../Images/coming_soon.png'
import { useTranslation } from 'react-i18next';
import Links from '../common/links'
import Table from '../common/table'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Home = () => {
    const { t } = useTranslation();
    const settings = {
        dots: false,
        infinite: true,
        speed: 4000,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1000,
    };
    const [isColumnLayout, setIsColumnLayout] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsColumnLayout(window.innerWidth < 768); // Change breakpoint as needed
        };
    
        // Initial check
        handleResize();
    
        // Add event listener to handle window resize
        window.addEventListener('resize', handleResize);
    
        // Cleanup
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    return (
            <div >
                <Header ></Header>
                <Links></Links>
                <div className="cover-div" style={{ width: '100%', backgroundImage: `url(${Background})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', display: 'flex', flexDirection: 'row', justifyContent: 'start' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '40VW', paddingTop: '5vW' }}>
                        <p className="title-text" style={{paddingLeft: '5vW', color: 'rgba(81,67,21,0.8)', textAlign: 'left', }}>{t('slider_title')}</p>
                        <p className="subtitle-text" style={{paddingLeft: '5vW', color: 'rgba(81,67,21,0.8)', textAlign: 'left', }}>{t('slider_text')}</p>
                    </div>
                </div>
                <div style={{ paddingTop: '5vW', paddingBottom: '5vW', backgroundColor: 'rgb(221 219 213)', paddingLeft: '15vW', paddingRight: '15vW' }}>
                    <p className="subtitle-text" style={{ textAlign: 'justify' }}>{t('home_subtext')}</p>
                </div>
                <p className="title-text" style={{ textAlign: 'center',color:'#9b9b9b' }}>{t('additional_partners')}</p>
                <div style={{ width: '100%'}}>
                    <Slider {...settings} >
                        {/* <div style={{ height: '20vH', width:'33%', display:'flex', justifyContent:'center' }}>
                            <img src={Friends01} style={{maxWidth:'80%'}}  />
                        </div>
                        <div style={{ height: '20vH', width:'33%',display:'flex', justifyContent:'center'  }}>
                            <img src={Friends02}  style={{maxWidth:'80%'}} />
                        </div> */}
                        <div style={{ height: 'auto', width:'33%',display:'flex', justifyContent:'center'  }}>
                            <img src={Friends03} style={{maxWidth:'80%'}} />
                        </div>
                        <div style={{ height: 'auto', width:'33%',display:'flex', justifyContent:'center' }}>
                            <img src={Friends04} style={{maxWidth:'80%'}}  />
                        </div>
                        <div style={{ height: 'auto', width:'33%',display:'flex', justifyContent:'center' }}>
                            <img src={Friends05} style={{maxWidth:'80%'}} />
                        </div>
                    </Slider>
                </div>
                <div className={`container ${isColumnLayout ? 'column-layout' : 'row-layout'}`} style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-around', background: 'rgba(0, 0, 0, 0.1)' }}>
                    {/* <img src={ComingSoon} style={{ objectFit: 'contain', height: 'auto', width: '40VW', margin: '5vW' }} /> */}
                    <iframe className="box" style={{ paddingLeft:'2vW', paddingBottom:'2vH',paddingTop:'2vH', width:"100%", height:"50VH"}} src="https://www.youtube.com/embed/HJYK8Pgfzpw" title="UTISCI POLAZNIKA KURSA UPRAVLJANJE TEHNIČKOM DOKUMENTACIJOM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <div  className="box" style={{ display: 'flex', flexDirection: 'column', width:'80%' }}>
                        <p className="titles" style={{ fontSize: '2Rem' }}>{t('youtube_title')}</p>
                        <p className="subtitle-text">{t('youtube_text_0')}</p>
                        <p className="subtitle-text">{t('youtube_text_1')}</p>
                    </div>
                </div>
                <Table></Table>
                <Partners></Partners>
                <Footer></Footer>
            </div>
    )
}

export default Home